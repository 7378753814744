/*
 * @Descripttion: 时间转换
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-06-28 16:27:09
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-21 16:20:40
 */
// 中国标准时间转正常时间格式
function timestampToTime(timestamp) {
    var Time;
    var date = new Date(timestamp);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    Time = y + '-' + m + '-' + d;
    return Time
}

// 中国标准时间转化成时间戳
function dateToMs(date) {
    let result = new Date(date).getTime();
    return result;
}
function dealdate(dealdate) {
    const date = new Date(dealdate)
    const year = date.getFullYear()   
    let month = date.getMonth() + 1
    month = month<10?"0"+month:month   
    let day = date.getDate()
    day = day<10?"0"+day:day        
    let hour = date.getHours()     
    hour = hour<10?"0"+hour:hour          
    let minute = date.getMinutes() 
    minute = minute<10?"0"+minute:minute  
    let second = date.getSeconds()
    second = second<10?"0"+second:second 
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
}
// 获取今日、本周、本月、本年开始，结束时间
function getStartEndByPeriod(period) {
    const currentDate = new Date();
    let startDate, endDate;
    if (period === '今日') {
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    } else if (period === '本周') {
      const currentDay = currentDate.getDay() || 7;
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDay + 1);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (7 - currentDay));
    } else if (period === '上周') {
      const currentDay = currentDate.getDay() || 7;
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDay - 6);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDay);
    } else if (period === '本月') {
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const nextMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
      endDate = new Date(nextMonthStartDate.getTime() - 1);
    } else if (period === '上月') {
      startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      endDate = new Date(currentMonthStartDate.getTime() - 1);
    } else if (period === '本季度') {
      const currentMonth = currentDate.getMonth();
      const quarter = Math.floor(currentMonth / 3);
      startDate = new Date(currentDate.getFullYear(), quarter * 3, 1);
      endDate = new Date(currentDate.getFullYear(), quarter * 3 + 3, 0);
    } else if (period === '本年') {
      startDate = new Date(currentDate.getFullYear(), 0, 1);
      const nextYearStartDate = new Date(currentDate.getFullYear() + 1, 0, 1);
      endDate = new Date(nextYearStartDate.getTime() - 1);
    } else {
      return '不支持该时间段';
    }
  
    return {
        'startDate': timestampToTime(startDate)+' 00:00:00',
        'endDate': timestampToTime(endDate)+' 23:59:59'
    };
}
export {
    dateToMs,
    timestampToTime,
    dealdate,
    getStartEndByPeriod
}
  
