<template>
    <div class="commonSelect-wrap">
        <div class="bw-top">
            <div class="filtrate-wrap">
                <div class="fw-date">
                    <div v-for="(item,index) in radioList" :key="index" @click="clickTagDate(index,item)"
                        class="date-item" :class="currentTag == index?'tagActive':''">
                            {{item.name}}
                    </div>
                    <el-date-picker v-if="isDate"
                        class="date-wrap"
                        v-model="dateValue"
                        type="daterange"
                        :picker-options="setDateRange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="changeDate"
                        >
                    </el-date-picker>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {timestampToTime} from "@/utils/timeCycle";
export default {
    data () {
        return {
            radioList: [{
                name: '全部',
                label: ''
            },{
              name: '今日',
              label: 'today'
            },
            // {
            //     name: '本周',
            //     label: 'week'
            // },
            {
                name: '本月',
                label: 'month'
            },
            // {
            //     name: '本年',
            //     label: 'year'
            // },
            {
                name: '自定义',
                label: 'date'
            }],
            currentTag: 0,
            isDate: false,
            dateValue: null,
            setDateRange: {  // 禁用今天之后的日期
                disabledDate: time => {
                    return time.getTime() > Date.now();
                }
            },
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            shopIds: [],
            startDate: '',
            endDate: ''
        }
    },
    mounted () {

    },
    methods: {
        //点击选择日期
        changeDate(date){
            if (this.dateValue && this.dateValue.length > 0) {
                const timestampBegin = +new Date(this.dateValue[0])
                const timestampEnd = +new Date(this.dateValue[1])
                if (timestampEnd > timestampBegin + 3600 * 1000 * 24 * 40) {
                    this.$alert('选择的时间范围不能超过40天', '提示', {
                            confirmButtonText: '确定',
                            type: 'warning'
                        })
                        .then(() => (this.dateValue = null))
                        .catch(() => (this.dateValue = null))
                }else{
                    this.startDate = timestampToTime(date[0]);
                    this.endDate = timestampToTime(date[1]);
                    this.$emit("changeDate",this.startDate,this.endDate)
                }
            }
        },
        // 点击切换
        clickTagDate(index,item){
            this.$emit("clickTagDate",item)
            this.currentTag = index
            if(item.label == 'date'){
                this.isDate = true;
            }else{
                this.isDate = false;
            }
        },
    }
}
</script>

<style scoped lang="scss">
.commonSelect-wrap{
    .bw-top{
        .filtrate-wrap{
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 6px;
            .fw-date{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .date-item{
                    margin-right: 10px;
                    border: 1px solid #ddd;
                    padding: 8px 18px;
                    border-radius: 6px;
                    font-size: 14px;
                    cursor: pointer;
                }
                .tagActive{
                    background: #0068ff;
                    color: #fff;
                }
                .date-wrap{
                    height: 37px;
                }
            }
            .fw-shop{
                border: 1px solid #ddd;
                padding: 8px 18px;
                border-radius: 6px;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
}
</style>
