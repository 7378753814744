<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-12-19 17:34:46
 * @LastEditors: alex.yang
 * @LastEditTime: 2023-12-26 09:48:28
-->
<template>
    <div class="userData">
        <div class="ud-title">
            <div class="ud-title-box">
                <div class="screen-left">
                    <commonSelect
                        @changeDate="changeDate"
                        @clickTagDate="clickTagDate"
                    ></commonSelect>
                </div>
                <div class="screen-right">
                    <div class="sr-item">
                        <div class="i-title">数据字段</div>
                        <el-select v-model="data_type" @change="changeSelect">
                            <el-option
                            v-for="item in typeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="sr-item">
                        <div class="i-title">销售负责人</div>
                        <el-select v-model="sale_id" @change="changeSelect">
                            <el-option
                            v-for="item in saleOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="sr-item">
                        <div class="i-title">服务负责人</div>
                        <el-select v-model="service_id" @change="changeSelect">
                            <el-option
                            v-for="item in serviceOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="ud-title-screen">
                <div class="sr-item">
                    <div class="i-title">行业类别</div>
                    <el-select v-model="industry_id" @change="changeSelect">
                        <el-option
                        v-for="item in industryOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="sr-item">
                    <div class="i-title">商户类型</div>
                    <el-select v-model="pro_type" @change="changeSelect">
                        <el-option
                        v-for="item in shopOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="sr-item">
                    <div class="i-title">供应商</div>
                    <el-select v-model="factory_id" @change="changeSelect">
                        <el-option
                        v-for="item in supplierOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="title-item">
                <div class="uc-l-title">
                    <div class="title">地区（省/自治区、市）</div>
                </div>
                <div class="uc-r-title">
                    <div class="title">行业</div>
                </div>
            </div>
        </div>
        <div class="ud-content-box" v-loading='loading'>
            <div class="uc-left">
                <div class="l-c-left">
                    <div class="lcl-item" :class="areaIndex == index?'active':''"
                        @click="clickArea(item,index)"
                        v-for="(item,index) in areaList" :key="index">
                        <div>{{item.name}}</div>
                        <div>{{item.data_num}}<span v-if="data_type == 3">%</span></div>
                    </div>
                </div>
                <div class="l-c-right">
                    <div class="lcr-item" :class="cityIndex == index?'active':''"
                        @click="clickCity(item,index)"
                        v-for="(item,index) in cityList" :key="index">
                        <div>{{item.name}}</div>
                        <div>{{item.data_num}}<span v-if="data_type == 3">%</span></div>
                    </div>
                </div>
            </div>
            <div class="uc-right">
                <div class="r-c-item" v-for="(item,index) in industryList" :key="index">
                    <div>{{item.name}}</div>
                    <div>{{item.data_num}}<span v-if="data_type == 3">%</span></div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
import {getStartEndByPeriod} from "@/utils/timeCycle.js"
import commonSelect from "@/components/commonSelect.vue"
export default {
    components:{
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'userData',
            typeOptions: [{
                value: 1,
                label: '商户数'
                }, {
                value: 2,
                label: '用户数'
                }, {
                value: 3,
                label: '商户活跃占比'
            }],
            shopOptions: [{
                value: '',
                label: '全部'
                }, {
                value: 1,
                label: '付费用户'
                }, {
                value: 2,
                label: '试用用户  '
            }],
            industryOptions: [],
            serviceOptions: [],
            saleOptions: [],
            supplierOptions: [],
            options: [{
                value: '选项1',
                label: '黄金糕'
                }, {
                value: '选项2',
                label: '双皮奶'
                }, {
                value: '选项3',
                label: '蚵仔煎'
                }, {
                value: '选项4',
                label: '龙须面'
                }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',

            start_date: '',
            end_date: '',
            data_type: 1,
            sale_id: '',
            service_id: '',
            industry_id: '',
            pro_type: '',
            factory_id: '',
            areaList: [],
            cityList: [],
            province: '',
            city: '',
            industryList: [],
            areaIndex: 0,
            cityIndex: 0,
            loading: false
        }
    },
    mounted () {
        this.getIndustryWhole();
        this.getServiceWhole();
        this.getSaleWhole();
        this.getSupplierWhole();
        this.getIndustryData()
        this.getAddressData();
    },
    methods: {
        // 选择市
        clickCity(item,index){
            this.city = item.name
            this.cityIndex = index
            if(index == 0){
                this.city = ''
            }
            this.getIndustryData()
        },
        // 选择省
        clickArea(item,index){
            this.areaIndex = index;
            this.cityIndex = 0
            this.city = ''
            this.cityList = item.city
            this.province = item.name
            console.log(this.province);
            this.getIndustryData()
        },
        // 筛选
        changeSelect(){
            this.getAddressData();
        },
        // 获取行业数据
        getIndustryData(){
            let params = {
                start_date: this.start_date,
                end_date: this.end_date,
                data_type: this.data_type,
                sale_id: this.sale_id,
                service_id: this.service_id,
                industry_id: this.industry_id,
                pro_type: this.pro_type,
                factory_id: this.factory_id,
                province: this.province,
                city: this.city
            }
            api.get('/service/data/get_industry_data', params, (res) =>  {
                this.industryList = res.data.list
            });
        },
        // 获取地址数据
        getAddressData(){
            this.loading = true
            let params = {
                start_date: this.start_date,
                end_date: this.end_date,
                data_type: this.data_type,
                sale_id: this.sale_id,
                service_id: this.service_id,
                industry_id: this.industry_id,
                pro_type: this.pro_type,
                factory_id: this.factory_id,
            }
            api.get('/service/data/get_address_data', params, (res) =>  {
                this.areaList = res.data.list
                this.cityList = res.data.list[0].city
                this.loading = false
            });
            setTimeout(() => {
                this.loading = false
            }, 500);
        },
        // 获取供应商列表
        getSupplierWhole(){
            api.get('/service/supplier/get_supplier_whole', {}, (res) =>  {
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                }
                })
                let quan = {
                    id: '',
                    name: '全部',
                }
                resultSecond.unshift(quan);
                this.supplierOptions = resultSecond
            });
        },
        // 获取行业类别
        getIndustryWhole(){
            api.get('/service/service/get_industry_whole', {}, (res) =>  {
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                }
                })
                let quan = {
                    id: '',
                    name: '全部',
                }
                resultSecond.unshift(quan);
                this.industryOptions = resultSecond
            });
        },
        // 获取服务负责人
        getServiceWhole(){
            api.get('/service/service/get_service_whole', {}, (res) =>  {
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                }
                })
                let quan = {
                    id: '',
                    name: '全部',
                }
                resultSecond.unshift(quan);
                this.serviceOptions = resultSecond
            });
        },
        // 获取销售负责人
        getSaleWhole(){
            api.get('/service/sale/get_sale_whole', {}, (res) =>  {
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                }
                })
                let quan = {
                    id: '',
                    name: '全部',
                }
                resultSecond.unshift(quan);
                this.saleOptions = resultSecond
            });
        },
        // 日期选择
        changeDate(startDate,endDate){
            console.log(startDate,endDate);
            this.start_date = startDate.slice(0,11)+' 00:00:01';
            this.end_date = endDate.slice(0,11)+' 23:59:59';
            this.getAddressData();
        },
        clickTagDate(item){
            console.log(item);
            if(item.label != 'date'){
                let date = getStartEndByPeriod(item.name)
                this.start_date = date.startDate;
                this.end_date = date.endDate;
                this.getAddressData();
            }
        },
    },
}
</script>

<style lang='scss'>
.userData{
    height: calc(100vh - 60px);
    overflow: hidden;
    .ud-title{
        position: sticky;
        top: 0;
        left: 0;
    }
    .ud-title-box{
        padding: 15px 40px 0 40px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        .screen-right{
            display: flex;
            flex-wrap: wrap;
            .sr-item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 30px;
                padding-bottom: 15px;
                .i-title{
                    font-size: 16px;
                    color: #0a032d;
                    padding-right: 20px;
                }
                .el-select{
                    width: 150px;
                }
            }
        }
    }
    .ud-title-screen{
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #ddd;
        padding: 15px 40px 0 40px;
        .sr-item{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-right: 30px;
            padding-bottom: 15px;
            .i-title{
                font-size: 16px;
                color: #0a032d;
            }
            .el-select{
                width: 150px;
                padding-left: 20px;
            }
        }
    }
    .title-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        .uc-l-title{
            flex: 3;
            height: 55px;
            background: #fff;
            line-height: 55px;
            border-right: 1px solid #ddd;
            .title{
                padding-left: 60px;
            }
        }
        .uc-r-title{
            flex: 2;
            height: 55px;
            background: #fff;
            line-height: 55px;
            .title{
                padding-left: 60px;
            }
        }
    }
    .ud-content-box{
        display: flex;
        justify-content: space-between;
        color: #0a032d;
        .uc-left{
            flex: 3;
            border-right: 1px solid #ddd;
            display: flex;
            justify-content: space-between;
            .l-c-left{
                flex: 1;
                border-right: 1px solid #ddd;
                background: #fff;
                height: calc(100vh - 260px);
                overflow-y: auto;
                .active{
                    color: #0068ff;
                }
                .lcl-item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #ddd;
                    height: 50px;
                    line-height: 50px;
                    padding: 0 40px;
                    cursor: pointer;
                }
            }
            .l-c-left::-webkit-scrollbar {
                display: none;
            }
            .l-c-right::-webkit-scrollbar {
                display: none;
            }
            .l-c-right{
                flex: 1;
                height: calc(100vh - 260px);
                overflow-y: auto;
                .active{
                    color: #0068ff;
                }
                .lcr-item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #ddd;
                    height: 50px;
                    line-height: 50px;
                    padding: 0 40px;
                    cursor: pointer;
                }
            }
        }
        .uc-right{
            flex: 2;
            height: calc(100vh - 260px);
            overflow-y: auto;
            .r-c-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #ddd;
                height: 50px;
                line-height: 50px;
                padding: 0 40px;
            }
        }
        .uc-right::-webkit-scrollbar {
            display: none;
        }
    }
}
</style>
